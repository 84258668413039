.wrapper.MainSolar {
  .welcomeLifeInsurance {
    p {
      font-family: $fontLato;
      font-weight: bold;
      font-size: pxToRem(29);
      margin-bottom: pxToRem(15);
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    ul.list-dot {
      li {
        font-size: pxToRem(18);
        line-height: 1.4;
        margin-bottom: 10px;
        font-family: $fontLato;
        font-weight: $regular;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: #212121;
        position: relative;

        &::before {
          content: "•";
          margin-right: pxToRem(5);
        }
      }
    }
  }

  .info-customer.MyHouse {
    max-width: pxToRem(370);
  }

  .step-sever {
    .info-customer {
      max-width: pxToRem(630);

      .wForm-input {
        padding-left: pxToRem(15);
        padding-right: pxToRem(15);

        .checkbox__label {
          width: 100%;
          text-align: center;
          padding-right: pxToRem(55);
        }
      }
    }
  }

  textarea.input-change {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
  }

  input.google-places-autocomplete__input {

    border: pxToRem(1) solid $color-enquiry-action;
    height: pxToRem(60);
    display: block;
    border-radius: pxToRem(7);
    font-size: pxToRem(18);
    padding: pxToRem(15);
    padding-bottom: pxToRem(8);
    color: $black-active;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $bold;
    -webkit-transition: width 0.15s, height 0.15s, background-size 0.15s;
    -moz-transition: width 0.15s, height 0.15s, background-size 0.15s;
    -o-transition: width 0.15s, height 0.15s, background-size 0.15s;
    transition: width 0.15s, height 0.15s, background-size 0.15s;
    outline: none;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }

    &.value {
      padding-top: pxToRem(20);
    }

    &:focus {
      border: pxToRem(2) solid $blue;
    }


  }

  .google-places-autocomplete__suggestions-container {
    background-color: #f7f7f7;
    border: 1px solid #dfdfdf;
    border-radius: pxToRem(6);
    top: 100%;
    margin-top: pxToRem(10);

    >div {
      font-family: $fontLato;
      font-size: pxToRem(17);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
      border-bottom: 1px solid #dfdfdf;
      padding: pxToRem(10) pxToRem(30) pxToRem(10) pxToRem(60);
      cursor: pointer;
      -webkit-transition: all 0.15s ease-out;
      -moz-transition: all 0.15s ease-out;
      -o-transition: all 0.15s ease-out;
      transition: all 0.15s ease-out;
      height: pxToRem(60);
      display: flex;
      align-items: center;

      &:hover,
      &.active {
        background-color: #dbdbdb;
      }


      &:last-child {
        border-bottom: 0;
      }
    }
  }


  @media screen and (max-width: 640px) {
    .welcomeLifeInsurance {
      .logo {
        margin-bottom: pxToRem(50);
      }

      p {
        font-family: $fontLato;
        font-weight: bold;
        font-size: pxToRem(16);
      }

      ul.list-dot {
        li {
          font-size: pxToRem(14);
          line-height: 1.4;
          padding: 0;
        }
      }
    }

    .google-places-autocomplete__suggestions-container {
      >div {
        font-size: pxToRem(15);
        padding: pxToRem(10) pxToRem(30) pxToRem(10) pxToRem(43);
      }
    }
  }
}

.life-insurance footer.footer-solar.footer-life-insurance {
  background-color: #f6f6f6;
  padding-bottom: 30px;

  .container {

    @include min-screen($width-site) {
      max-width: pxToRem(1100);
    }

    @include max-screen($width-site) {
      margin-top: 0;
    }
  }
}

.main-refinance-fb {
  .welcomeLifeInsurance {
    position: relative;

    .btn-reset {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: pxToRem(45);
      margin-top: pxToRem(-15);
      opacity: 0.5;
      z-index: 999;

      @media screen and (max-width: 992px) {
        right: pxToRem(15);
      }

      img {
        width: pxToRem(22);
        height: pxToRem(26);
      }
    }

    @media screen and (max-width: 480px) {
      h1 {
        letter-spacing: -0.07rem;
        line-height: 1.3;

        span {
          text-transform: capitalize;
        }

        br.d-block {
          display: none !important;
        }
      }
    }
  }

  .MainSolarNow .MainLifeInsurance {
    background-image: url("../../images/life/Layer-10.png");

    &:after {
      background-color: rgba(#006aaf, 0.9);
    }
  }

  .MainSolarNow {
    .content-box {
      @media screen and (min-width: 992px) {
        min-height: 500px;
      }
    }
  }

  .image-author {
    img {
      border: 0 !important;
    }
  }
}

.MainSolarNow {
  .MainLifeInsurance {
    padding: 50px 0;
    background-image: url("../../images/life/Layer-9.png");
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    min-height: pxToRem(900);

    @include max-screen($screen-xs) {
      min-height: auto;
    }

    * p {
      font-family: "Lato";
    }

    &:after {
      content: "";
      background-color: rgba(#006aaf, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }

    .welcomeLifeInsurance {
      margin: 0;
      margin-bottom: 40px;

      .logo {
        margin-bottom: 50px;
      }

      h1,
      p {
        color: #fff;
      }

      .list-dot {
        li {
          color: #fff !important;
        }
      }
    }

    >section {
      position: relative;
      z-index: 2;
    }
  }

  .content-box {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 10px 40px -10px rgb(46 159 233 / 36%);
    max-width: 860px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: 30px 20px;
    min-height: 400px;

    h2 {
      margin-bottom: 20px;

      @include max-screen($screen-xs) {
        font-size: pxToRem(23);
      }
    }

    .group-rating {
      max-width: 300;
      margin-right: auto;
      margin-left: auto;

      * {
        font-family: "Lato";
      }

      .group-img-star {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        img {
          width: 25px;
          height: 25px;
          margin: 5px;
        }
      }

      .number {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #212121;
        letter-spacing: -0.3px;
        margin-bottom: 10px;
      }

      .text {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        color: #212121;
        letter-spacing: -0.3px;
      }
    }

    .info-customer {
      &.max-600 {
        max-width: pxToRem(600);

        .checkbox .checkbox__label {
          text-align: center;
          display: block;
          padding-left: 0;
        }
      }

    }

    @include max-screen($screen-xs) {
      p {
        font-size: pxToRem(14);
      }

      .input__box .input__label {
        font-size: pxToRem(12);
      }

      .progressBar-group {
        .label {
          font-size: pxToRem(16);
        }
      }
    }
  }
}

.debt-fb.MainSolarNow .MainLifeInsurance:after {
  display: none;
}

.main-superannuation .MainSolarNow {
  .MainLifeInsurance {
    background-color: rgba(0, 106, 175, 0.9);
    background-image: url("../../images/life/BG_superannuation.png");

    &::after {
      display: none;
    }
  }
}

.solar-rating {
  padding-bottom: 50px !important;
  padding-top: 50px;

  @include max-screen($screen-xs) {
    padding-bottom: 0 !important;
    padding-top: 0;
  }

  .bg-quotes {
    font-family: "Lato";
    background-color: #fff;

    .image-author {
      img {
        box-shadow: 0px 10px 40px -10px rgb(46 159 233 / 36%);
      }
    }

    .content-quotes {
      .desc {
        font-size: pxToRem(18);
        line-height: 1.4;
        margin-bottom: 20px;
      }

      .text-author {
        font-size: pxToRem(16);
      }
    }
  }

  @include max-screen($width-site) {
    padding-bottom: 0;

    .bg-quotes {

      .content-quotes {
        .desc {
          font-size: pxToRem(14);
          line-height: 1.3;
        }

        .text-author {
          font-size: pxToRem(14);
        }
      }
    }
  }
}

footer.position-relative {
  bottom: 0 !important;
}

.mobileNoHomeowner {
  .progress .progress-bar {
    background-color: red !important;
  }
}

.font-15 {
  font-size: pxToRem(25) !important;
}

.group-debt {
  img {
    max-width: 65px;
  }

  p {
    color: #001d30;
    text-align: center;
    font-size: pxToRem(18) !important;
    line-height: 1.4;
    margin-top: 10px;
    font-weight: 700;
    font-family: "Lato";
  }
}

.group-table {
  padding: 0 20px;
  display: flex;
  align-items: center;

  .item-table {
    border: 2px solid #09be81;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    width: 33%;
    margin: 0 15px;
    margin-bottom: 20px;

    &.active1,
    &:hover,
    &:focus {
      .table-head {
        background-color: #00e767;
        color: #ffffff;
      }

      background-color: #d6ffe8;
    }

    * {
      font-family: $fontLato;
      color: #212121;
      text-align: center;
      transition: all 0.2s;
    }

    &.Medium {
      border: 2px solid #ffbb19;

      .table-head {
        border-bottom: 2px solid #ffbb19;
        background-color: #ffefc8;
      }

      .table-sub {
        border-bottom: 2px solid #ffbb19;
      }

      &.active2,
      &:hover,
      &:focus {
        .table-head {
          background-color: #ffb400;
          color: #fff;
        }

        background-color: #fff2d3;
      }
    }


    &.High {
      border: 2px solid #ff0000;

      .table-head {
        border-bottom: 2px solid #ff0000;
        background-color: #ffcccc;
      }

      .table-sub {
        border-bottom: 2px solid #ff0000;
      }

      &.active3,
      &:hover,
      &:focus {
        .table-head {
          background-color: #ff0000;
          color: #fff;
        }

        background-color: #ffefef;
      }
    }

    .table-head {
      border-bottom: 2px solid #09be81;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 60px;
      background-color: #5effc8;
      font-size: pxToRem(18);
      font-weight: 700;
      top: -0.3px;
      position: relative;
      right: -0.5px;
      left: -0.5px;
      width: calc(100% + 1px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .table-sub {
      font-size: pxToRem(17);
      min-height: 60px;
      padding: 20px 25px;
      border-bottom: 2px solid #09be81;
      line-height: 1.4;

      &.sub3 {
        border-bottom: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  @media screen and (max-width:992px) {
    padding: 0 10px;

    .item-table {
      width: 33%;
      margin: 0 8px;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 0 20px;
    display: block;

    .item-table {
      width: 100%;
      margin: 0;
      margin-bottom: 30px;
    }
  }
}