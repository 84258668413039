@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?sq26ib');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?sq26ib#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?sq26ib') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?sq26ib') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?sq26ib#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-checked-2:before {
  content: "\e907";
}
.icon-icon-lock:before {
  content: "\e906";
}
.icon-icon-checked:before {
  content: "\e905";
}
.icon-icon-close:before {
  content: "\e904";
}
.icon-icon-alert:before {
  content: "\e900";
}
.icon-icon-arrow-left:before {
  content: "\e901";
}
.icon-icon-arrow-right:before {
  content: "\e902";
}
.icon-icon-enter:before {
  content: "\e903";
}